import axios from 'axios';

export default
    axios.create({
        // baseURL: 'http://127.0.0.1:8000/api/',
        baseURL: 'https://nerd-api.buzzprivilegewifi.com/api/',
        // baseURL: 'https://api.socio.co.th/api/',
        headers: {'Authorization' : 'Bearer '+ localStorage.getItem('accessToken'), 
                   'Content-Type': 'application/json',}

    }); 